<div *ngIf="showHeader" class="top-bar shadow">
    <a href="/" class="logo-container ">
        <img alt="Logo" *ngIf="!appSession.tenant || !appSession.tenant.HasLogo()"
            src="/assets/common/images/app-logo-on-{{skin}}-sm.svg" class="logo" />
        <img alt="Logo" *ngIf="appSession.tenant && appSession.tenant.HasLogo()"
            [src]="remoteServiceBaseUrl + '/TenantCustomization/GetTenantLogo?skin=' + skin + '&tenantId=' + appSession.tenant.id"
            class="logo" />
        <img alt="Logo" *ngIf="appSession.tenant && appSession.tenant.HasLogo()"
            [src]="remoteServiceBaseUrl + '/TenantCustomization/GetTenantLogo?skin=' + skin + '&tenantId=' + appSession.tenant.id"
            class="logo" />
    </a>

    <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1 ms-10">
        <div class="d-flex align-items-stretch" id="kt_header_nav">
            <div class="header-menu align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="header-menu"
                data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true"
                data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start"
                data-kt-drawer-toggle="#kt_header_menu_mobile_toggle" data-kt-swapper="true"
                data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}">
                <top-bar-menu class="w-100"></top-bar-menu>
            </div>
        </div>

        <div class="d-flex align-items-stretch flex-shrink-0">
            <div class="topbar">
                <!-- <feedback-button></feedback-button> -->

                <button
                    class='btn btn-active-color-primary btn-active-light  h-35px h-md-40px position-relative w-35px w-md-40px'
                    (click)="showFeedback()">
                    <i class='fa fa-flask'></i>
                </button>

                <active-delegated-users-combo></active-delegated-users-combo>
                <subscription-notification-bar
                    [customStyle]="'btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px position-relative'"></subscription-notification-bar>
                <quick-theme-selection
                    [customStyle]="'btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px position-relative'"></quick-theme-selection>
                <language-switch-dropdown
                    [customStyle]="'btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px position-relative'"></language-switch-dropdown>
                <header-notifications
                    [customStyle]="'btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px position-relative'"></header-notifications>
                <chat-toggle-button
                    [customStyle]="'btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px position-relative'"></chat-toggle-button>
                <toggle-dark-mode
                    [customStyle]="'btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px position-relative'"
                    [isDarkModeActive]="isDarkModeActive()"></toggle-dark-mode>
                <user-menu [iconOnly]="true"
                    [togglerCssClass]="'btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px position-relative'"></user-menu>

            </div>
        </div>
    </div>
</div>
<div class="main-and-sidebar">
    <div *ngIf="showSideBar" class="side-bar shadow">
        <side-bar-menu></side-bar-menu>
        <div style="height: 100%"></div>
    </div>

    <div class="main-view">
        <div class="main-view-content">
            <router-outlet></router-outlet>
            <div *ngIf="showFooter" style="flex-grow: 1"></div>
            <div *ngIf="showFooter" class="footer-section shadow">
                <footer-bar></footer-bar>
            </div>
        </div>
        <feedback-modal #feedbackModal></feedback-modal>
    </div>
</div>