<div class="p40 g20">
    <sub-header [title]="'Notifications' | localize">
            <button class="btn btn-success" (click)="openNotificationSettingsModal()">
                <i class="fa fa-cog btn-md-icon"></i>
                <span class="d-none d-md-inline-block">
                    {{ 'NotificationSettings' | localize }}
                </span>
            </button>
            <button class="btn btn-primary" (click)="setAllNotificationsAsRead()">
                <i class="fa fa-check btn-md-icon"></i>
                <span class="d-none d-md-inline-block">
                    {{ 'SetAllAsRead' | localize }}
                </span>
            </button>
    </sub-header>
    <div class="container">
        <div class="card card-custom">
            <div class="card-body">
                <div class="form">
                    <div class="row mb-4">
                        <div class="col-xl-6">
                            <input class="form-control" id="StartEndRange" name="StartEndRange" #drp="bsDaterangepicker"
                                readonly="readonly" bsDaterangepicker dateRangePickerLuxonModifier
                                [(date)]="dateRange" />
                        </div>
                        <div class="col-xl-6">
                            <div class="mb-5">
                                <select #TargetValueFilterSelectionCombobox class="form-control"
                                    [(ngModel)]="readStateFilter" name="readStateFilter">
                                    <option value="ALL">{{ 'All' | localize }}</option>
                                    <option value="UNREAD">{{ 'Unread' | localize }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12 text-end">
                            <div class="horizontal g10">
                                <button class="btn btn-danger" (click)="deleteNotifications()">
                                    <i class="la la-times"></i>
                                    {{ 'DeleteListedNotifications' | localize }}
                                </button>
                                <button class="btn btn-primary" (click)="getNotifications()">
                                    <i class="fa fa-sync"></i>
                                    {{ 'Refresh' | localize }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <!--<Primeng-TurboTable-Start>-->
                    <div class="col primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                        <p-table #dataTable (onLazyLoad)="getNotifications($event)" [value]="primengTableHelper.records"
                            rows="{{ primengTableHelper.defaultRecordsCountPerPage }}" [paginator]="false" [lazy]="true"
                            [tableStyle]="{'min-width': '50rem'}">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 100px; text-align: center">
                                        {{ 'Actions' | localize }}
                                    </th>
                                    <th style="width: 80px; text-align: center">{{ 'Severity' | localize }}</th>
                                    <th>
                                        {{ 'Notification' | localize }}
                                    </th>
                                    <th style="width: 200px; text-align: center">
                                        {{ 'CreationTime' | localize }}
                                    </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-record="$implicit">
                                <tr>
                                    <td style="width: 130px; justify-content: start;">
                                        <button class="btn btn-sm btn-icon btn-primary"
                                            [attr.title]="!isRead(record) ? l('SetAsRead') : ''"
                                            (click)="setAsRead(record)" [disabled]="isRead(record)">
                                            <i class="fa fa-check" *ngIf="isRead(record)"
                                                [attr.aria-label]="l('Read')"></i>
                                            <i class="fa fa-circle-notch" *ngIf="!isRead(record)"
                                                [attr.aria-label]="l('Unread')"></i>
                                        </button>
                                        <button class="btn btn-sm btn-icon btn-danger" [attr.title]="l('Delete')"
                                            (click)="deleteNotification(record)">
                                            <i class="la la-times" [attr.aria-label]="l('Delete')"></i>
                                        </button>
                                    </td>
                                    <td style="text-align: center">
                                        <i class="{{ record.formattedNotification.icon }} {{
                                                record.formattedNotification.iconFontClass
                                            }} fa-2x" tooltip="{{
                                                getNotificationTextBySeverity(record.formattedNotification.severity)
                                            }}"></i>
                                    </td>
                                    <td>
                                        <span class="p-column-title">{{ 'Notification' | localize }}</span>
                                        <a *ngIf="record.formattedNotification.url"
                                            href="{{ record.formattedNotification.url }}"
                                            class="{{ getRowClass(record) }}">
                                            {{ truncateString(record.formattedNotification.text, 120) }}
                                        </a>
                                        <span *ngIf="!record.formattedNotification.url"
                                            title="{{ record.formattedNotification.text }}"
                                            class="{{ getRowClass(record) }}">
                                            {{ truncateString(record.formattedNotification.text, 120) }}
                                        </span>
                                    </td>
                                    <td style="text-align: center">
                                        <span title="{{ record.notification.creationTime | luxonFormat: 'DDDD t' }}"
                                            class="{{ getRowClass(record) }}">
                                            {{ fromNow(record.notification.creationTime) }}
                                        </span>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                            {{ 'NoData' | localize }}
                        </div>
                        <div class="primeng-paging-container">
                            <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator
                                (onPageChange)="getNotifications($event)"
                                [totalRecords]="primengTableHelper.totalRecordsCount"
                                [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage"
                                [showCurrentPageReport]="true" [currentPageReportTemplate]="
                                    'TotalRecordsCount' | localize: primengTableHelper.totalRecordsCount
                                "></p-paginator>
                        </div>
                    </div>
                    <!--<Primeng-TurboTable-End>-->
                </div>
            </div>
        </div>
    </div>
</div>
