import { Component } from '@angular/core';

@Component({
  selector: 'app-deudat-spinner',
  templateUrl: './deudat-spinner.component.html',
  styleUrls: ['./deudat-spinner.component.scss']
})
export class DeudatSpinnerComponent {

}
