import { Component, Injector, ViewChild } from '@angular/core';
import { Route, Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CreateFeedBackDto, FeedBackServiceProxy } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'feedback-modal',
    templateUrl: './feedback-modal.component.html',
    styleUrls: ['./feedback-modal.component.scss'],
})
export class FeedbackModalComponent extends AppComponentBase {
    @ViewChild('feedbackModal', { static: true }) modal: ModalDirective;
    saving = false;
    anonymous = true;
    feedBack = new CreateFeedBackDto();
    constructor(injector: Injector, private feedbackService: FeedBackServiceProxy, private router: Router) {
        super(injector);
        this.feedBack.subject = '';
        this.feedBack.comment = '';
        this.feedBack.rating = 0;
        this.feedBack.isAnonymous = false;
    }

    show() {
        this.modal.show();
    }

    save() {
        this.feedBack.page = this.router.url;
        this.feedbackService.createFeedBack(this.feedBack).subscribe({
            next: (data) => {
                this.notify.success(this.l('FeedbackSent'));
                this.modal.hide();
                if (this.router.url === '/app/admin/feedback') {
                    window.location.reload();
                }
            },
            error: (error) => {
                this.notify.error(this.l('FeedbackCouldNotBeDelivered'));
                console.log(error);
            },
        });
    }

    close() {
        this.modal.hide();
    }

    setRating(value: number) {
        this.feedBack.rating = value;
    }

    onShown() {}
}
