import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { FeedbackModalComponent } from '@app/shared/feedback-modal/feedback-modal.component';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'feedback-button',
    templateUrl: './feedback-button.component.html',
    styleUrls: ['./feedback-button.component.css'],
})
export class FeedbackButtonComponent extends AppComponentBase {}
