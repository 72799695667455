import { NgModule } from '@angular/core';
import {
    NavigationEnd,
    NavigationStart,
    Router,
    RouterModule,
} from '@angular/router';
import { AppComponent } from './app.component';
import { AppRouteGuard } from './shared/common/auth/auth-route-guard';
import { NotificationsComponent } from './shared/layout/notifications/notifications.component';
import { NgxSpinnerService } from 'ngx-spinner';

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: 'app',
                component: AppComponent,
                canActivate: [AppRouteGuard],
                canActivateChild: [AppRouteGuard],
                children: [
                    { path: '', children: [
                        { path: 'notifications', component: NotificationsComponent },
                        { path: '', redirectTo: '/app/main/dashboard', pathMatch: 'full' }
                    ], },
                    { path: 'main', loadChildren: () => import('app/main/main.module').then((m) => m.MainModule),  data: { preload: true } },
                    { path: 'admin', loadChildren: () => import('app/admin/admin.module').then((m) => m.AdminModule), data: { preload: true }, canLoad: [AppRouteGuard] },
                    { path: 'academy', loadChildren: () => import('app/academy/academy.module').then((m) => m.AcademyModule), data: { preload: true }, canLoad: [AppRouteGuard] },
                    { path: 'demo', loadChildren: () => import('app/demo/demo.module').then( (m) => m.DemoModule ), data: { permission: 'Pages.DemoUiComponents' }, },
                    { path: 'isrm', loadChildren: () => import('app/isrm/isrm.module').then((m) => m.IsrmModule), data: { preload: true }, canLoad: [AppRouteGuard] },
                    { path: '**', redirectTo: 'notifications' },
                ],
            },
        ]),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(private router: Router, private spinnerService: NgxSpinnerService) {
        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                spinnerService.show();
            }

            if (event instanceof NavigationEnd) {
                document.querySelector('meta[property=og\\:url').setAttribute('content', window.location.href);
                spinnerService.hide();
            }
        });
    }
}
