<div class='deudat-spinner'>
    <div style="animation-delay: 0.2s;"></div>
    <div style="animation-delay: 0.3s;"></div>
    <div style="animation-delay: 0.4s;"></div>
    <div style="animation-delay: 0.1s;"></div>
    <div style="animation-delay: 0.2s;"></div>
    <div style="animation-delay: 0.3s;"></div>
    <div style="animation-delay: 0.0s;"></div>
    <div style="animation-delay: 0.1s;"></div>
    <div style="animation-delay: 0.2s;"></div>
</div>
