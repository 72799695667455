<div appBsModal #feedbackModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog"
  aria-labelledby="feedbackModal" aria-hidden="true" [config]="{ backdrop: 'static', keyboard: !saving }">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <form #feedbackForm="ngForm" role="form" novalidate class="form-validation">

        <!-- <div class="modal-header">

        </div> -->

        <div class="modal-body">

          <div class="horizontal">
            <h1 class="modal-title fg">
              <span>{{ 'Feedback' | localize }}</span>
            </h1>
            <button type="button" class="btn-close" (click)="close()" [attr.aria-label]="l('Close')"
              [disabled]="saving">
            </button>
          </div>

          <div class="smiley-container">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" [class.active]="feedBack.rating == 2"
              (click)="setRating(2)">
              <path
                d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm130.7 57.9c-4.2-13.6 7.1-25.9 21.3-25.9l212.5 0c14.2 0 25.5 12.4 21.3 25.9C369 368.4 318.2 408 258.2 408s-110.8-39.6-127.5-94.1zm86.9-85.1s0 0 0 0c0 0 0 0 0 0l-.2-.2c-.2-.2-.4-.5-.7-.9c-.6-.8-1.6-2-2.8-3.4c-2.5-2.8-6-6.6-10.2-10.3c-8.8-7.8-18.8-14-27.7-14s-18.9 6.2-27.7 14c-4.2 3.7-7.7 7.5-10.2 10.3c-1.2 1.4-2.2 2.6-2.8 3.4c-.3 .4-.6 .7-.7 .9l-.2 .2c0 0 0 0 0 0c0 0 0 0 0 0s0 0 0 0c-2.1 2.8-5.7 3.9-8.9 2.8s-5.5-4.1-5.5-7.6c0-17.9 6.7-35.6 16.6-48.8c9.8-13 23.9-23.2 39.4-23.2s29.6 10.2 39.4 23.2c9.9 13.2 16.6 30.9 16.6 48.8c0 3.4-2.2 6.5-5.5 7.6s-6.9 0-8.9-2.8c0 0 0 0 0 0s0 0 0 0zm160 0c0 0 0 0 0 0l-.2-.2c-.2-.2-.4-.5-.7-.9c-.6-.8-1.6-2-2.8-3.4c-2.5-2.8-6-6.6-10.2-10.3c-8.8-7.8-18.8-14-27.7-14s-18.9 6.2-27.7 14c-4.2 3.7-7.7 7.5-10.2 10.3c-1.2 1.4-2.2 2.6-2.8 3.4c-.3 .4-.6 .7-.7 .9l-.2 .2c0 0 0 0 0 0c0 0 0 0 0 0s0 0 0 0c-2.1 2.8-5.7 3.9-8.9 2.8s-5.5-4.1-5.5-7.6c0-17.9 6.7-35.6 16.6-48.8c9.8-13 23.9-23.2 39.4-23.2s29.6 10.2 39.4 23.2c9.9 13.2 16.6 30.9 16.6 48.8c0 3.4-2.2 6.5-5.5 7.6s-6.9 0-8.9-2.8c0 0 0 0 0 0s0 0 0 0s0 0 0 0z" />
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" [class.active]="feedBack.rating == 1"
              (click)="setRating(1)">
              <path
                d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm177.6 62.1C192.8 334.5 218.8 352 256 352s63.2-17.5 78.4-33.9c9-9.7 24.2-10.4 33.9-1.4s10.4 24.2 1.4 33.9c-22 23.8-60 49.4-113.6 49.4s-91.7-25.5-113.6-49.4c-9-9.7-8.4-24.9 1.4-33.9s24.9-8.4 33.9 1.4zM144.4 208a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" [class.active]="feedBack.rating == 0"
              (click)="setRating(0)">
              <path
                d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM256 0a256 256 0 1 0 0 512A256 256 0 1 0 256 0zM176.4 240a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm192-32a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM184 328c-13.3 0-24 10.7-24 24s10.7 24 24 24l144 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-144 0z" />
            </svg>


            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" [class.active]="feedBack.rating == -1"
              (click)="setRating(-1)">
              <path
                d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM182.4 382.5c-12.4 5.2-26.5-4.1-21.1-16.4c16-36.6 52.4-62.1 94.8-62.1s78.8 25.6 94.8 62.1c5.4 12.3-8.7 21.6-21.1 16.4c-22.4-9.5-47.4-14.8-73.7-14.8s-51.3 5.3-73.7 14.8zM144.4 208a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" [class.active]="feedBack.rating == -2"
              (click)="setRating(-2)">
              <path
                d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM174.6 384.1c-4.5 12.5-18.2 18.9-30.7 14.4s-18.9-18.2-14.4-30.7C146.9 319.4 198.9 288 256 288s109.1 31.4 126.6 79.9c4.5 12.5-2 26.2-14.4 30.7s-26.2-2-30.7-14.4C328.2 358.5 297.2 336 256 336s-72.2 22.5-81.4 48.1zM144.4 208a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
            </svg>

          </div>


          <!-- <p-rating name="moin" [(ngModel)]="feedBack.rating" [cancel]="false"></p-rating> -->

          <div class="g10">
            <input class="form-control" type="text" id="subjectField" [placeholder]="'ShortTitle' | localize"
              [(ngModel)]="feedBack.subject" name="subjectField" />
          </div>
          <textarea class="form-control" name="message" rows="4" cols="50" [(ngModel)]="feedBack.comment"
            [placeholder]="'YourMessage' | localize">
          </textarea>
          <div class="horizontal g5">
            <input class="form-check-input" type="checkbox" id="anonymousCheckbox" name="anonymousCheckbox"
              [(ngModel)]="feedBack.isAnonymous">
            <label for="anonymousCheckbox">{{"Anonymous" | localize}}</label>
          </div>

          <button [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')" (click)="save()"
            class="btn btn-primary fw-bold">
            <span>{{'Send' | localize }}</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>