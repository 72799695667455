import { Injectable } from '@angular/core';

@Injectable()
export class SlugService {
    updateInputToSlug(text: string): string {
        return text.toLowerCase().replace(/\s+/g, '-');
    }

    convertToSlug(text: string): string {
        let slug = text.trim();
        slug = slug.replace(/ä/g, 'ae').replace(/ö/g, 'oe').replace(/ü/g, 'ue').replace(/ß/g, 'ss');
        slug = slug.replace(/\s+/g, '-');
        slug = slug.replace(/[^\w\s-]/g, '');
        slug = slug.toLowerCase();
        slug = slug.replace(/-+/g, '-');
        return slug;
    }
}
