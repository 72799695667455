import { DOCUMENT } from '@angular/common';
import { AppConsts } from '@shared/AppConsts';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import {
    Injector,
    ElementRef,
    Component,
    OnInit,
    AfterViewInit,
    ViewChild,
    Inject,
    ViewEncapsulation,
    Input,
} from '@angular/core';
import { Router } from '@angular/router';
import { FeedbackModalComponent } from '@app/shared/feedback-modal/feedback-modal.component';

@Component({
    templateUrl: './theme3-layout.component.html',
    selector: 'theme3-layout',
    styleUrls: ['./theme3-layout.component.scss'],
    animations: [appModuleAnimation()],
    encapsulation: ViewEncapsulation.None,
})
export class Theme3LayoutComponent extends ThemesLayoutBaseComponent implements OnInit, AfterViewInit {
    @ViewChild('ktHeader', { static: true }) ktHeader: ElementRef;

    @ViewChild('feedbackModal', { static: true }) feedbackModal: FeedbackModalComponent;

    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
    skin = this.currentTheme.baseSettings.layout.darkMode ? 'dark' : 'light';
    showFooter = true;
    showHeader = true;
    showSideBar = true;

    constructor(
        injector: Injector,
        private router: Router,
        _dateTimeService: DateTimeService,
        @Inject(DOCUMENT) private document: Document
    ) {
        super(injector, _dateTimeService);
        this.checkRoute();
        router.events.subscribe((event) => {
            if (event.constructor.name === 'NavigationEnd') {
                this.checkRoute();
            }
        });
    }

    showFeedback() {
        this.feedbackModal.show();
    }

    checkRoute() {
        if (this.router.url.includes('embeddedcourse')) {
            this.showHeader = false;
            this.showFooter = false;
            this.showSideBar = false;
            return;
        }
        this.showFooter = true;
        this.showHeader = true;
        this.showSideBar = true;
    }

    ngOnInit() {
        this.installationMode = UrlHelper.isInstallUrl(location.href);
    }

    ngAfterViewInit(): void {}

    toggleLeftAside(): void {
        this.document.body.classList.toggle('header-menu-wrapper-on');
        this.document.getElementById('kt_header_menu_wrapper').classList.toggle('header-menu-wrapper-on');
    }
}
