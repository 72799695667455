import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[preventEnterSubmit]',
})
export class PreventEnterSubmitDirective {
    @HostListener('keydown.enter', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        event.preventDefault();
    }
}
