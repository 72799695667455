<div class="card title-bar">
    <div class="titles">
        <h1 class="title">
            {{ title }}
        </h1>
        <div *ngIf="description" class="secondary-title">
            {{ description }}
        </div>
    </div>
    <div class="action-section">
        <ng-content></ng-content>
    </div>
</div>
